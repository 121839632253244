//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      dialogVisible: false,
      myForm: {
        content: "",
        contact: ""
      },
      myRules: {
        content: [
          {
            required: true,
            message: "请输入您要反馈的问题",
            trigger: "blur"
          },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value.length < 4) {
                callback(new Error("请至少输入4个字"));
              } else {
                callback();
              }
            }
          }
        ]
      }
    };
  },
  mounted() {},
  methods: {
    showModel() {
      if (this.$store.state.user) {
        this.myForm.contact = this.$store.state.user.uc_mobile;
      }
      this.dialogVisible = true;
    },
    subInfo() {
      this.myForm.url = window.location.href;
      this.$refs.myRules.validate(valid => {
        if (valid) {
          this.$axios.post("/api/feedback/add/", this.myForm).then(res => {
            if (res.data.code == 0) {
              this.$message.success("提交成功，感谢您的反馈");
              this.dialogVisible = false;
              this.myForm.content = null;
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
