//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Loading } from "element-ui";
import SvgIcon from "../components/SvgIcon.vue";
import { isContinueRenderPage } from "../utils/check.js";

export default {
  components: { SvgIcon },
  data() {
    return {
      noScroll: false,
      isShow: false,
      noticeList: [],
      noticeTotal: "", // 总通知数量
      inMail: "", // 未读站内信数量
      exportData: {},
      timer: null,
      crrActive: 0,
      isQuery: !(
        this.$route.path.includes("queryReport") && this.$route.query.query == 1
      )
        ? false
        : true // 商标查询分享页
    };
  },
  computed: {
    menuDataRes(){
      let data = this.tools.menuData.filter( item => item.index !== '9');
      if(this.$store.state.user && this.$store.state.user.uc_memberType === 1) {
        data = this.tools.menuData.filter( item => item.index !== '9' && item.index !== '12');
      }
      if(this.$store.state.user && this.$store.state.user.uc_memberType === 2) {
        data = this.tools.menuData.filter( item => item.index !== '9' && item.index !== '11');
      }
      if(this.$store.state.user && this.$store.state.user.uc_memberType === 4) {
        data = this.tools.menuData.filter( item => item.index !== '11');
      }
      return data
    },
    activeMenu(){
      return this.$route.path
    }
  },
  async mounted() {
    const loadingInstance = Loading.service({
      text: "加载中..."
    });
    try {
      await isContinueRenderPage.bind(this)();
    } catch (e) {
      loadingInstance.close();
      console.error("isContinueRenderPage执行失败，跳转到登录页");
      this.$router.push("/login");
      return;
    }

    loadingInstance.close();
    this.isShow = true;

    document.addEventListener("keydown", this.arrowUpDown);
    let _router = this.$route;
    if (!(_router.path.includes("queryReport") && _router.query.query == 1)) {
      // 分享出去的页面不调用该接口
      this.listInmail();
      this.noticeInfo();
      let _this = this;
      window.sendEventCall = (num, type) => {
        _this.noticeInfo();
        _this.exportData.total = "";
        type == "notice" && (_this.inMail = 0);
      };
      this.timer = setInterval(() => {
        setTimeout(_this.noticeInfo, 0);
      }, 1000 * 5 * 60);
    }
    if (process.client && this.$store.state.user) {
      var st = document.createElement("style");
      st.innerHTML = this.$store.state.user.uc_css + "{display: none;}";
      document.body.appendChild(st);
    }
    console.log(this.$route.path)


    this.noScroll = this.$route.path === '/analysis/newBj'

    if(this.tools.menuData[9] && this.tools.menuData[9].name =='我的线索' && this.$store.state.user.uc_memberType !=3){
      this.tools.menuData.splice(9,1)
    }
  },
  methods: {
    goRoute(vc){
      if(vc.index === '/trademarkSearch'){ // 存cookie，显示初始查询页面
        this.$cookies.set("showInto", true)
      }
      if(vc.index === '/trademarkSearchInternational'){ // 存cookie，显示初始查询页面
        this.$cookies.set("showInterInto", true)
      }
      if(vc.index === this.$route.path){
        location.reload()
      } else {
        this.$router.push(vc.index)
      }

    },
    arrowUpDown(e) {
      if (e.target.nodeName === "INPUT" || e.target.nodeName === "TEXTAREA") {
        return;
      }
      if (e.code === "ArrowUp" || e.code === "ArrowDown") {
        e.preventDefault(); //阻止默认事件
        let dom = document.getElementById("RightPlan");
        let top = dom.scrollTop;

        if (e.code === "ArrowUp") {
          let num = 4;
          const timeTop = setInterval(() => {
            dom.scrollTop = top -= 10;
            num--;
            if (num <= 0) {
              clearInterval(timeTop);
            }
          }, 10);
        } else if (e.code === "ArrowDown") {
          let num = 4;
          const timeTop = setInterval(() => {
            dom.scrollTop = top += 10;
            num--;
            if (num <= 0) {
              clearInterval(timeTop);
            }
          }, 10);
        }
      }
    },
    /*pageClick(url){
      if(url === '/trademarkSearch'){ // 存cookie，显示初始查询页面
        this.$cookies.set("showInto", true)
      }
      if(url === this.$route.path){
        location.reload()
      }
    },*/
    SHMenu() {
      let menu = !this.$store.state.isHideMenu;
      this.$store.commit("setMenu", menu);
      let nodeList = document.querySelectorAll(".j-scroll-left");
      let fixedlen = document.querySelectorAll(".fixed-head").length;
      if (nodeList.length) {
        nodeList[0].style.paddingLeft = !fixedlen || menu ? "0" : "166px";
        if (nodeList.length >= 2) {
          nodeList[1].style.paddingLeft = !fixedlen || menu ? "20px" : "186px";
        }
      }

      let jsc = document.querySelectorAll(".j-scroll-contrast");
      let fixedc = document.querySelectorAll(".fixed-head-contrast").length;
      if (jsc.length) {
        jsc.forEach(item => {
          item.style.paddingLeft = !fixedc ? "0" : menu ? "95px" : "225px";
        });
      }
    },
    listInmail() {
      this.loading = true;
      this.$axios
        .post("/api/Inmail/getList", { num: 3, sort: "desc" })
        .then(res => {
          let _data = res.data.data;
          this.noticeList = _data.rows;
          this.noticeTotal = _data.total;
        });
    },
    noticeInfo() {
      this.$axios
        .get("/api/Member/getNoticeNumber", {
          params: { id: Math.random() }
        })
        .then(res => {
          let _data = res.data.data;
          if (_data) {
            this.exportData = _data.export;
            this.inMail = _data.inMail;
            if (this.exportData) {
              if (this.exportData.successReport) {
                if (document.querySelector(".tipsSuccess")) {
                  console.log(`存在`);
                  return;
                }
                this.$notify({
                  title: "成功",
                  position: "bottom-left",
                  message: "您有新的导出任务已完成",
                  type: "success",
                  customClass: "tipsSuccess",
                  offset: 100,
                  duration: 10000,
                  onClose: () => {
                    this.reportClose(1);
                  }
                });
              } else if (this.exportData.failReport) {
                this.$notify.error({
                  title: "错误",
                  position: "bottom-left",
                  offset: 100,
                  message: "您有导出任务失败了",
                  duration: 10000,
                  onClose: () => {
                    this.reportClose(2);
                  }
                });
              }
            }
          }
        });
    },
    reportClose(num) {
      this.$axios
        .post("/api/member/closeExportReport/", { status: num })
        .then(res => {
          console.log(res);
          this.noticeInfo();
        });
    },
    openDetails(Id) {
      window.open("/user/noticeDetails?id=" + Id);
      this.noticeInfo();
    },

  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  }
};
