/**`
 * 是否继续渲染页面
 */

export function isContinueRenderPage() {
  /**
   * 如果是白名单中的页面，则通过
   */
  // const url = location.href;
  // let brandQuery = url.includes("queryReport") // 商标分享页
  // let objQuery = url.includes("objection/details"); // 异议文书分享页
  // if (brandQuery || objQuery) {
  //   return Promise.resolve();
  // }
  

  /**
   * 否则检查用户权限
   */
  return this.$axios
    .post("/api/auth/getUserInfo")
    .then(res => {
      this.$store.commit("setUser", res.data.data);
    })
    .catch((e) => {
      console.log('屏蔽utils/check.js抛出的错误',e)
      // 如果没有权限，则抛出错误
      // throw new Error("获取用户信息失败，未登录");
    });
}
